import { openNav, closeNav } from "./modules/navigation.js";
import { countdown } from "./modules/countdown.js";

// Stop transitions before page has loaded
$(window).on("load", function () {
  $("body").removeClass("preload");
});

// Toggle navigation
$("#hamburger").click(function (e) {
  e.preventDefault();
  $("#main-navigation").hasClass("hidden") ? openNav() : closeNav();
});

// Reset if browser is resized
$(window).resize(function () {
  if ($(window).width() >= 900) {
    closeNav();
  }
});

// Start countdown
setInterval(function () {
  countdown($("#countdown").data("time"));
}, 1000);

// Page scroll anchor links
var currentHash = "#";
var anchors = $(".anchor-target");

$(".anchor").click(function () {
  $(".anchor").removeClass("bg-blue");
  $(this).addClass("bg-blue");
});

$(document).scroll(function () {
  var currentTop = window.pageYOffset / 1 + 80;

  for (var i = 0; i < anchors.length; i++) {
    var currentElementTop = $(anchors[i]).offset().top;
    var hash = $(anchors[i]).attr("id");

    if (
      currentElementTop < currentTop &&
      currentTop < currentElementTop + $(anchors[i]).height() &&
      currentHash != hash
    ) {
      if (history.pushState) {
        history.pushState(null, null, "#" + hash);
      } else {
        location.hash = "#" + hash;
      }

      $('a[href!="#' + hash + '"]').removeClass("bg-blue");
      $('a[href="#' + hash + '"]').addClass("bg-blue");

      currentHash = hash;
    }
  }
});
